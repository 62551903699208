<template>
  <div class="favorites">
    <div class="favorites__container _container">
      <div class="loader" v-if="loader === null">loading</div>
      <h1
        class="favorites__title main-title"
        v-if="loader === false && products.products !== []"
      >
        {{ $t("searching") }}:
        <font color="#D81921">{{ this.$route.query.text }}</font>
      </h1>
      <h1
        class="favorites__title main-title"
        v-if="loader === false && products.products === []"
      >
        {{ $t("notFound") }}
        <font color="#D81921">{{ this.$route.query.text }}</font>
      </h1>
      <div class="favorites__body">
        <div class="favorites__items">
          <div
            class="favorites__item"
            v-for="product in products.data"
            :key="product"
          >
            <ProductItem :product="product"></ProductItem>
          </div>
        </div>
        <div
          style="display: flex; justify-content: center"
          v-if="products.last_page > 1"
        >
          <vue-awesome-paginate
            :total-items="products.last_page * 5"
            :items-per-page="5"
            :max-pages-shown="5"
            v-model="currentPage"
            @click="onClickHandler"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductItem from "@/components/ProductItem";
import axios from "axios";
import "vue-awesome-paginate/dist/style.css";

export default {
  components: {
    ProductItem,
  },

  data() {
    return {
      loader: null,
      products: [],
      currentPage: 1,
    };
  },
  created() {
    this.findSearch();
  },
  methods: {
    onClickHandler() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
      this.findSearch();
    },
    findSearch() {
      axios
        .get(`/search`, {
          params: {
            text: this.$route.query.text,
            page: this.currentPage,
          },
          headers: {
            Authorization: localStorage.getItem("access_token")
              ? `Bearer ${localStorage.getItem("access_token")}`
              : null,
          },
        })
        .then((response) => {
          this.products = response.data.products;
          this.loader = false;
        })

        .catch((error) => {
          this.loader = false;
        });
    },
  },
  computed: {},
  watch: {
    "$route.query.text": function () {
      this.loader = null;
      this.findSearch();
    },
  },
};
</script>

<style lang="scss">
.favorites {
  width: 100%;
  padding: 40px 0 92px 0;
  @media (max-width: $mobileSmall + px) {
    padding: 32px 0 112px 0;
  }

  &__title {
    margin: 0 0 49px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 45px 0;
    }
  }

  &__items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1%;
    row-gap: 15px;
    margin: 0 0 40px 0;
    @media (max-width: $mobileSmall + px) {
      gap: 10px;
      margin: 0 0 35px 0;
    }
  }

  &__item {
    align-self: normal;
    width: 24%;
    @media (max-width: $tablet + px) {
      flex: 0 1 calc(33% - 11px);
    }
    @media (max-width: $mobile + px) {
      flex: 0 1 calc(50% - 11px);
    }
    @media (max-width: $mobileSmall + px) {
      flex: 0 1 calc(50% - 5px);
    }
  }

  &__text {
    margin: 0 0 20px 0;
  }

  &__btn {
    padding: 0 22px;
    @media (max-width: $mobileSmall + px) {
      width: 100%;
    }
  }
}
.loader {
  overflow: hidden;
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #d81921;
  background: -moz-linear-gradient(
    left,
    #d81921 10%,
    rgba(101, 171, 248, 0) 42%
  );
  background: -webkit-linear-gradient(
    left,
    #d81921 10%,
    rgba(101, 171, 248, 0) 42%
  );
  background: -o-linear-gradient(left, #d81921 10%, rgba(101, 171, 248, 0) 42%);
  background: -ms-linear-gradient(
    left,
    #d81921 10%,
    rgba(101, 171, 248, 0) 42%
  );
  background: linear-gradient(
    to right,
    #d81921 10%,
    rgba(101, 171, 248, 0) 42%
  );
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before {
  width: 50%;
  height: 50%;
  background: #d81921;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.loader:after {
  background: white;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>